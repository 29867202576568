<template>
	<v-row no-gutters class="feedback-box flex-column">
		<div class="feedback-header d-flex align-center px-3">
			<p class="heading-mf15">Special interaction</p>
			<p class="text-btn ma-0 ml-auto" v-if="show" v-on:click="show = !show">Hide</p>
			<p class="text-btn ma-0 ml-auto" v-else v-on:click="show = !show">Show</p>
		</div>
		<transition name="show-hide" tag="div">
			<div v-show="show" class="feedback-content pa-3">
				<div>
					<div>{{ value }}</div>
				</div>
			</div>
		</transition>
	</v-row>
</template>

<script>
export default {
	props: {
		value: String
	},
	data() {
		return {
			show: true
		};
	},

	computed: {
		Show() {
			return this.show === false ? "display:none" : "display:block";
		}
	}
};
</script>